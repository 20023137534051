// ** Icons Import
import { Home, Circle } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    badge: 'light-warning',
    badgeText: '1',
    children: [
     
      {
        id: 'agromath_dom',
        title: 'Agromath',
        icon: <Circle size={12} />,
        navLink: '/dashboard/dom'
      },
      {
        id: 'agromath_map',
        title: 'Mapa',
        icon: <Circle size={12} />,
        navLink: '/dashboard/map'
      }
    ]
  }
]
