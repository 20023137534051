// ** Icons Import
import { Home, Circle } from 'react-feather'

export default [
  {
    id: 'riegos',
    title: 'Riegos',
    icon: <Home size={20} />,
    badge: 'light-warning',
    badgeText: '1',
    children: [
     
      {
        id: 'riegos_registro',
        title: 'Registro riegos',
        icon: <Circle size={12} />,
        navLink: '/riegos/registro'
      }
    ]
  }
]
