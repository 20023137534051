import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'))
const DashboardEcommerce = lazy(() => import('../../views/dashboard/ecommerce'))
const Agromath_dom = lazy(() => import('../../views/dashboard/dom'))
const Agromath_map = lazy(() => import('../../views/dashboard/maps'))

const DashboardRoutes = [
  {
    path: '/dashboard/analytics',
    element: <DashboardAnalytics />
  },
  {
    path: '/dashboard/ecommerce',
    element: <DashboardEcommerce />
  },
  {
    path: '/dashboard/dom',
    element: <Agromath_dom />
  },
  {
    path: '/dashboard/map',
    element: <Agromath_map />
  }

]

export default DashboardRoutes
