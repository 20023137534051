/* define configuraciones generales para todo el site */

//const backEnd_agromath = 'https://wwlat0tqgk.execute-api.us-west-2.amazonaws.com/develop/pssdom'
/*EC2*/
//const backEnd_agromath = 'http://ec2-54-245-174-249.us-west-2.compute.amazonaws.com:8000'
/*local*/
const backEnd_agromath = 'http://ec2-54-245-174-249.us-west-2.compute.amazonaws.com:8000'

const sse_backEnd_Agromath = 'http://ec2-54-245-174-249.us-west-2.compute.amazonaws.com:8000'
export const api_agromath = `${backEnd_agromath}/api`
export const login_agromath = `${backEnd_agromath}/login/`
export const sse_agromath   = `${sse_backEnd_Agromath}/events`
