import { lazy } from 'react'

const RegistroRiego = lazy(() => import('../../views/riegos/registro'))

const RiegosRoutes = [
  {
    path: '/riegos/registro',
    element: <RegistroRiego />
  }

]

export default RiegosRoutes
