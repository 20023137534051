
import  {  useEffect } from "react"
import {handleTelemetry, handleConnected } from '@store/telemetrySlice'
import { useDispatch, useSelector } from 'react-redux'
import {sse_agromath} from './api-agromath/config'
// eslint-disable-next-line no-unused-vars
import { JsonWebTokenError } from "jsonwebtoken"
// eslint-disable-next-line no-unused-vars
import { info } from "sass"
//import reducer from './features/telemetry/telemetrySlice';

// eslint-disable-next-line no-unused-vars
function MessageSender({ ...props }) {
    const dispatch = useDispatch()
    const clientID = useSelector(state => state.auth.client_id)
    const _URL = `${sse_agromath}`
    useEffect(() => {
        
        if (clientID)   {
            //cargamos las PSS asociadas al cliente 
            console.log(`Activando SSE:${_URL}?clientid=${clientID}`)
            const events = new EventSource(`${_URL}?clientid=${clientID}`)
            events.onmessage = e => {
                const msg = JSON.parse(e.data)
                console.log(msg.type)
                switch (msg.type) {
                    case "DATA":
                        dispatch(handleTelemetry(msg))
                        
                    case "EVENT":
                        console.log(msg)
                        break
                    default:
                        dispatch(handleConnected(msg.lenght > 0))
                }
                
            }
        } else {
            console.log('No hay cliente seleccionado aún')
        }

   }, [clientID])
    
    
    return null


}

export default (MessageSender)
