// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { percentH } from '@utils'
/*
const initTodayValue = {minValue:{T:50, Hr:110, H:100},
                        maxValue:{T:-50, Hr:0, H:0}
                        }
                       
const updateTelemetry = (oldValue, newValue) => {
    const Tmin = (oldValue.minValue.T > newValue.T) ? newValue.T : oldValue.minValue.T
    const Tmax = (oldValue.maxValue.T < newValue.T) ? newValue.T : oldValue.minValue.T

    const Hmin = (oldValue.minValue.H > newValue.H) ? newValue.H : oldValue.minValue.H
    const Hmax = (oldValue.maxValue.H < newValue.H) ? newValue.H : oldValue.minValue.H

    const HRmin = (oldValue.minValue.Hr > newValue.Hr) ? newValue.Hr : oldValue.minValue.Hr
    const HRmax = (oldValue.maxValue.Hr < newValue.Hr) ? newValue.Hr : oldValue.minValue.Hr

    const today = {minValue:{T:Tmin, Hr:HRmin, H:Hmin},
                 maxValue:{T:Tmax, Hr:HRmax, H:Hmin}
                }
    const lastValue = {T:newValue.T, Hr:newValue.H, H:newValue.H}
                    

    return {LastValues: lastValue, today}
}


// prepara la estructura JSON, para almacenar los últimos valores recibidos. 
function buildTodayStruct(state, value) {
    const extrangeValue = state.pss_today.filter(item => item.pss_uuid)
    const new_today = extrangeValue.filter(item => item.pss_uuid !== value.pss_uuid)
    return new_today.concat({ pss_uuid:value.pssID, valores:updateTelemetry(initTodayValue, value.telemetry)})

}

*/


//pss_today: arreglo json para mantener los valores del día para las PSS del cliente
export const telemetry = createSlice({
    name :'telemetria',
    initialState: {
        lastHsuelo: 0,
        lastTemp: 0,
        lastHr: 0,
        isConnected: false,
        pss_attached: [],
        pss_today:[],
        pss_selected: null
      },
    reducers: {

        handleSelectedPss: (state, action) => {
            state.pss_selected = action.payload

        },
        // eslint-disable-next-line no-unused-vars
        handleTelemetry: (state, action) => {
           // const jsonVar = JSON.parse(action.payload)
            console.log('telemetrySlice: Modificando estados')
            //state.pss_today = buildTodayStruct(state, action.payload.payload)
            //state.lastHsuelo = action.payload.payload.telemetry.H
            state.lastHsuelo = percentH(action.payload.payload.telemetry.H)
            state.lastTemp   = action.payload.payload.telemetry.T
            state.lastHr     = action.payload.payload.telemetry.Hr
            //uuid = action.payload.uuid
            //pssID = action.payload.payload.pssID
            //lastHsuelo = action.payload.payload.telemetry.H
        
        },
        
        handleLoadPSS: (state, action) => {
            console.log('telemetrySlice: Actualizando listado PSS')
            state.pss_attached = action.payload
           
        },
        handleConnected: (state, action) => {

            state.isConnected = action.payload

        }

        
    }
})

//export const LastHsuelo = state => state.telemetria.lastHsuelo
export const {  handleTelemetry, handleConnected, handleLoadPSS, handleSelectedPss} = telemetry.actions

export default telemetry.reducer
