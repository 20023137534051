// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

const CustomNavbar = (props) => {
  console.log(props)
  return <p className='mb-0'>Visualización de datos</p>
}
const VerticalLayout = props => {
  // const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  // useEffect(() => {
  //   axios.get(URL).then(response => setMenuData(response.data))
  // }, [])

  return (
    <Layout navbar={props => <CustomNavbar {...props} />} menuData={navigation} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
